import React, {useState} from "react";
import PropTypes from "prop-types";
import { SessionContext } from "layouts/Dashboard.jsx";
import { NotificationManager } from "react-notifications";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import SelectWidget from "@rjsf/material-ui/lib/SelectWidget";
import Form from '@rjsf/material-ui';
import Button from '@material-ui/core/Button';
import validator from '@rjsf/validator-ajv8';
import {Card, CardContent} from "@material-ui/core";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";
import Api from "modules/api.js";
import applyNavs from "react-jsonschema-form-pagination";
import Zones from "./Zones.jsx";
import AlarmProfile from "./AlarmProfile.jsx";
import "../../assets/css/cartwatch_config.css";

function EditorWidget(props) {
    const { instanceName, config, type, selectOptions } = props.options._extra;
    const editorMap = {
        zones: Zones,
        alarm_profile: AlarmProfile
    };
    const [editor, toggleEditor] = useState(false);
    const [value, setValue] = useState(props.value);
    /* Fetching app name */
    let node = config;
    props.id.split("_").forEach(function (item) {
        if(!["root", type].includes(item) && Object.hasOwn(node, item)) { node = node[item]; }
    });
    if(!Object.hasOwn(node, "name")) {
        NotificationManager.error(`App name for id ${props.id} not found, can't use ${type} editor.`, "Editor error");
    }
    const Editor = editorMap[type];
    let selectProps = {...props};
    selectProps.options.enumOptions = selectOptions;
    return (
        <>
            <div style={{display: "flex"}}>
                <div style={{flex: "90%"}}>
                    <SelectWidget {...selectProps}
                                  onChange={(formData) =>
                                  {props.onChange(formData); setValue(formData); toggleEditor(false);}}
                    />
                </div>
                <div style={{flex: "10%"}}>
                    <Button variant="outlined"
                            color="secondary"
                            style={{margin: "0.75em 0 0 0.75em", whiteSpace: "nowrap"}}
                            onClick={() => {toggleEditor(!editor);}}>{editor ? 'Close Editor' : 'Open Editor'}</Button>
                </div>
            </div>
            {editor &&
                <Card style={{margin: "1em 0 1em 0", background: "#FCFCFC"}}>
                    <CardContent>
                        <Editor instanceName={instanceName} appName={node.name} fileName={value} />
                    </CardContent>
                </Card>}
        </>);
}

class CartwatchConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blockRendering: true,
        };
        this.reloadConfig = this.reloadConfig.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.loadData(this.props.context.instance_id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.context.instance_id !== prevProps.context.instance_id) {
            this.setState({blockRendering: true});
            if (typeof (this.props.context.instance_id) != "undefined") {
                this._isMounted && this.loadData(this.props.context.instance_id);
            }
        }
    }

    reloadConfig() {
        this._isMounted && this.loadData(this.props.context.instance_id);
    }

    loadData(instanceId) {
        if (instanceId === undefined) {
            return;
        }
        Api.get(`/api/new_config/${instanceId}/config/`).then((res) => {
            this.setState({
                blockRendering: false,
                config: res.config,
                configPath: res.config_path,
                configSchema: res.config_schema,
                availableZonesFiles: res.available_zones_files,
                availableProfilesFiles: res.available_profile_files
            });
        }).catch((e) => {
            NotificationManager.error(e.message, "Error loading Cartwatch config!");
        });
    }

    saveData(instanceId, data) {
        if (instanceId === undefined) {
            return;
        }
        Api.put(`/api/new_config/${instanceId}/config/`, data).then((res) => {
            NotificationManager.success("Cartwatch config saved!");
        }).catch((e) => {
            NotificationManager.error(e.message, "Error saving Cartwatch config!");
        });
    }

    render() {
        if (this.state.blockRendering) {
            return (<h3>Loading...</h3>);
        }
        else {
            const uiSchema = {
                "ui:title": "Config path: " + this.state.configPath,
                base: {nav: "Base"},
                pipeline: {nav: "Pipeline"},
                tracking: {nav: "Tracking"},
                broker: {nav: "Broker"},
                sources: {nav: "Sources"},
                models: {nav: "Models"},
                apps: {
                    nav: "Apps",
                    items: {
                        zones: {
                            anyOf: [{
                                "ui:widget": EditorWidget,
                                "ui:options": {
                                    "_extra": {
                                        instanceName: this.props.context.instance_id,
                                        config: this.state.config,
                                        type: "zones",
                                        selectOptions: this.state.availableZonesFiles.map((item) => {return {label: item, value: item}}),
                                    }
                                }
                            }]
                        },
                        alarm_profile: {
                            anyOf: [{
                                "ui:widget": EditorWidget,
                                "ui:options": {
                                    "_extra": {
                                        instanceName: this.props.context.instance_id,
                                        config: this.state.config,
                                        type: "alarm_profile",
                                        selectOptions: this.state.availableProfilesFiles.map((item) => {return {label: item, value: item}}),
                                    }
                                }
                            }]
                        }
                    }
                },
                stats: {nav: "Stats"}
            }
            let FormWithPagination = applyNavs(Form);
            return (
                <FormWithPagination uiSchema={uiSchema}
                                    title={this.state.configPath}
                                    schema={this.state.configSchema}
                                    formData={this.state.config}
                                    validator={validator}
                                    onSubmit={(e) => this.saveData(this.props.context.instance_id, e.formData)}/>
            );
        }
    }
}

CartwatchConfig.propTypes = {
    classes: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
};

function withSessionContext(Component) {
    return function WrapperComponent(props) {
        return (
            <SessionContext.Consumer>
              {(state) => <Component {...props} context={state}/>}
            </SessionContext.Consumer>
        );
    };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(CartwatchConfig)));
